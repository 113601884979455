$(document).ready(function () {
    const slickCarousel = $('.slick-carousel'),
        ledCards = $('.led-cards'),
        slickPrecision = $('.slick-precision'),
        imgPlaceholder = $('.img-placeholder'),
        accordionLinks = $('.platform-accordion a'),
        topArrow = $('.top-arrow'),
        bottomArrow = $('.bottom-arrow'),
        precisionLeft = $('.left-arrow'),
        precisionRight = $('.right-arrow'),
        accordion = $('#accordion');

    ledCards.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    slickPrecision.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        prevArrow: precisionLeft,
        nextArrow: precisionRight,
        draggable: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true
                }
            }
        ]
    });

    imgPlaceholder.hover(function () {
        $(this).toggleClass("hover-overlay");
    });

    slickCarousel.each(function () {
        let slider = $(this);

        slider.slick({
            draggable: false,
            infinite: false,
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: topArrow,
            nextArrow: bottomArrow
        });
    });

    let currentIndex = 0;

    accordionLinks.on('click', function (e) {
        e.preventDefault();
        currentIndex = $(this).data("slide");
        slickCarousel.slick('slickGoTo', currentIndex);
    });

    topArrow.on('click', function (e) {
        e.preventDefault();
        currentIndex === 0 ? currentIndex = 0 : currentIndex -= 1;
        accordion.find("[data-slide='" + currentIndex + "']").click();
    });

    bottomArrow.on('click', function (e) {
        e.preventDefault();
        currentIndex >= 3 ? currentIndex = 3 : currentIndex += 1;
        accordion.find("[data-slide='" + currentIndex + "']").click();
    });
});
